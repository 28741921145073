
import { cn } from "@/lib/utils";
import { Icon } from "@iconify/react";
import noData from '@assets/no-data-animate.svg'
import loading from '@assets/process-animate.svg'

export default function Empty({ text, icon, className = "", children, loader = false, ...rest }) {
    return (
        <div
            {...rest}
            className={cn(
                "flex flex-col justify-center w-full gap-4 h-full flex-grow border-none text-md text-primary font-semibold text-center items-center",
                className,
            )}
        >
            {icon && !loader && <Icon icon={icon} width={100} className="text-primary" />}
            {!icon && !loader && <img className="max-w-[250px]" src={noData} />}
            {loader && <img className="max-w-[250px]" src={loading} />}
            {text}
            {children}
        </div>
    );
}
